import React, { Component } from 'react';
import Helmet from 'react-helmet';
import PageHeader from '../PageComponents/PageHeader';
import Html from 'react-html-parser';
export default class FacebookAppPrivacyPolicy extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
	  	gravContent: this.props.pageContext.frontmatter,
	  	headContent: this.props.pageContext.head,
	  	questions: [],
	  	searchField: '',
	  	filteredQuestions: [],
	  	wrapperStyle:{},
	  	article: this.props.pageContext.content,
	  };
	}
	render() {
		return (
			<main>
				<Helmet>
					<title>Facebook Privacy Policy | New Image Keto</title>	
				</Helmet>	
				<div className="pageGrid clearfix">
					<section>
						<PageHeader mainHeading="Facebook App<br/> Privacy Policy" subHeading=""/>
					</section>
					<section>{Html(this.state.article)}</section>
				</div>
			</main>
		);
		
		
	}
}
